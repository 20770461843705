/**
 * DreamPay REST API
 * API to support DreamPay applications
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EstablishmentsComplianceForm { 
    id?: number;
    establishmentID?: number;
    establishmentOperationDate?: string;
    headOfficeType?: string;
    timeInAddress?: string;
    establishmentIntermediation?: boolean;
    productsOrServices?: string;
    targetAudience?: string;
    ticketSize?: string;
    siteLink?: string;
    exchangePolicies?: boolean;
    instagramProfile?: string;
    facebookProfile?: string;
    linkedinProfile?: string;
    otherProfile?: string;
    ibanSameCountryHeadOffice?: boolean;
    justificationIBANSameCountryHeadOffice?: string;
    clientInfoData?: boolean;
    justificationClientInfoData?: string;
    checkComercialCapacity?: boolean;
    kycProgram?: boolean;
    kycProgramInfo?: string;
    pldCftProgram?: boolean;
    pldCftProgramInfo?: string;
    pepPolicies?: boolean;
    justificationPepPolicies?: string;
    sellToMinors?: boolean;
    moneyLaundryTraining?: boolean;
    levelOfDueDiligence?: boolean;
    justificationLevelOfDueDiligence?: string;
    revisionProcess?: boolean;
    explanationRevisionProcess?: string;
    civilComercialCapacity?: boolean;
}

