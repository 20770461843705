import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './shared/components/notfound/notfound.component';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from './shared/guards/auth.guard';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppLayoutComponent,
          canActivate: [AuthGuard],
          children: [
            {
              path: '',
              loadChildren: () => import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule)
            },
            {
              path: 'end-report',
              loadChildren: () => import('./views/end-report/end-report.module').then((m) => m.EndReportModule)
            },
            {
              path: 'link-generator',
              loadChildren: () =>
                import('./views/link-generator/link-generator.module').then((m) => m.LinkGeneratorModule)
            },
            {
              path: 'link-generator-checkout',
              loadChildren: () =>
                import('./views/link-generator-checkout/link-generator-checkout.module').then(
                  (m) => m.LinkGeneratorCheckoutModule
                )
            },
            {
              path: 'link-generator-custas',
              loadChildren: () =>
                import('./views/link-generator-custas/link-generator-custas.module').then(
                  (m) => m.LinkGeneratorCustasModule
                )
            },
            {
              path: 'payment-links',
              loadChildren: () => import('./views/payment-links/payment-links.module').then((m) => m.PaymentLinksModule)
            },
            {
              path: 'payment-links-checkout',
              loadChildren: () =>
                import('./views/payment-links-checkout/payment-links-checkout.module').then(
                  (m) => m.PaymentLinksCheckoutModule
                )
            },
            {
              path: 'payment-links-custas',
              loadChildren: () =>
                import('./views/payment-links-custas/payment-links-custas.module').then((m) => m.PaymentLinksCustasModule)
            },
            {
              path: 'pos',
              loadChildren: () => import('./views/pos/pos.module').then((m) => m.PosModule)
            },
            {
              path: 'profile',
              loadChildren: () => import('./views/profile/profile.module').then((m) => m.ProfileModule)
            },
            {
              path: 'transactions',
              loadChildren: () => import('./views/transactions/transactions.module').then((m) => m.TransactionsModule)
            }
          ]
        },
        {
          path: 'auth',
          loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
        },
        { path: 'notfound', component: NotfoundComponent },
        { path: '**', redirectTo: '/' }
      ],
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload'
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
