import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const version = require('../../../package.json').version;

Sentry.init({
  dsn: 'https://d2d55e4c2bfc466a865aa9b4f34fa089@o1114358.ingest.sentry.io/6145635',
  release: version,
  environment: environment.env,
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: [
        'localhost',
        'https://dev-private-api.dreampay.com.br',
        'https://private-api.dreampay.com.br',
        'https://linkgenerator.dreampay.com.br',
        'https://dev-linkgenerator.dreampay.com.br',
        /^\//
      ],
      routingInstrumentation: Sentry.routingInstrumentation
    })
  ],

  ignoreErrors: ['auth/wrong-password', 'auth/user-not-found'],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
