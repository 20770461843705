import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { TranslateService } from '@ngx-translate/core';
import { Establishments } from 'api-client';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];
  i18Section = 'NAV';
  translationStrings: any;
  establishment: Establishments;

  constructor(
    public layoutService: LayoutService,
    private _translate: TranslateService
  ) {
    this.establishment = JSON.parse(localStorage.getItem('establishment'));
  }

  ngOnInit() {
    this._initTranslate();
  }
  private _initTranslate() {
    this._translate.get([this.i18Section]).subscribe((translations) => {
      this.translationStrings = translations[this.i18Section];
      this._initMenu();
    });
  }

  private _initMenu() {
    this.model =
      this.establishment?.countryID === 24
        ? [
            {
              label: '',
              items: [
                {
                  label: this.translationStrings?.DASHBOARD,
                  icon: 'pi pi-fw pi-home',
                  routerLink: ['/']
                },
                {
                  label: this.translationStrings?.LINK_GENERATOR_CUSTAS,
                  icon: 'pi pi-fw pi-th-large',
                  routerLink: ['/link-generator-custas']
                },
                {
                  label: this.translationStrings?.PAYMENT_LINKS_CUSTAS,
                  icon: 'pi pi-fw pi-link',
                  routerLink: ['/payment-links-custas']
                },
                {
                  label: this.translationStrings?.TRANSACTIONS,
                  icon: 'pi pi-fw pi-dollar',
                  routerLink: ['/transactions']
                },
                {
                  label: this.translationStrings?.PROFILE,
                  icon: 'pi pi-fw pi-user',
                  routerLink: ['/profile']
                }
              ]
            }
          ]
        : [
            {
              label: '',
              items: [
                {
                  label: this.translationStrings?.DASHBOARD,
                  icon: 'pi pi-fw pi-home',
                  routerLink: ['/']
                },
                {
                  label: this.translationStrings?.LINK_GENERATOR,
                  icon: 'pi pi-fw pi-cog',
                  routerLink: ['/link-generator']
                },
                {
                  label: this.translationStrings?.PAYMENT_LINKS,
                  icon: 'pi pi-fw pi-link',
                  routerLink: ['/payment-links']
                },
                {
                  label: this.translationStrings?.LINK_GENERATOR_CHECKOUT,
                  icon: 'pi pi-fw pi-shopping-cart',
                  routerLink: ['/link-generator-checkout']
                },
                {
                  label: this.translationStrings?.PAYMENT_LINKS_CHECKOUT,
                  icon: 'pi pi-fw pi-link',
                  routerLink: ['/payment-links-checkout']
                },
                {
                  label: this.translationStrings?.TRANSACTIONS,
                  icon: 'pi pi-fw pi-dollar',
                  routerLink: ['/transactions']
                },
                {
                  label: this.translationStrings?.ENDREPORT,
                  icon: 'pi pi-fw pi-file',
                  routerLink: ['/end-report']
                },
                {
                  label: this.translationStrings?.POSS,
                  icon: 'pi pi-fw pi-mobile',
                  routerLink: ['/pos']
                },
                {
                  label: this.translationStrings?.PROFILE,
                  icon: 'pi pi-fw pi-user',
                  routerLink: ['/profile']
                }
              ]
            }
          ];
  }
}
