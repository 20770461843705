/**
 * DreamPay REST API
 * API to support DreamPay applications
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DreampayPaymentResponse { 
    returnCode?: string;
    returnMessage?: string;
    paymentID?: string;
    tid?: string;
    authorizationCode?: string;
    proofSale?: string;
    paymentAccountReference?: string;
    receivedDate?: string;
}

