/**
 * DreamPay REST API
 * API to support DreamPay applications
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DreampayPayment { 
    securityCode?: string;
    softDescriptor: string;
    posID: string;
    establishmentID?: number;
    establismentName: string;
    purchaseValueEUR?: number;
    totalValueEUR?: number;
    totalValueBRL?: number;
    installmentValueBRL?: number;
    paymentMethod?: number;
    installments?: number;
    readMethod?: number;
    cardHolder: string;
    cpFofCardOwner?: string;
    cpfName?: string;
    cardNumber: string;
    cardExpirationDate: string;
    cardType: string;
    exchangeRate?: number;
    orderNumber: string;
    customerName: string;
    paymentLink?: string;
    customerEmail?: string;
    currencyCode?: string;
    customerPhoneNumber?: string;
    customerAddress?: string;
    customerZipCode?: string;
    customerCity?: string;
    customerCountryID?: number;
    customerCPFCNPJ?: string;
    cardProcessingComission?: number;
    estimatedExchangeCostBRL?: number;
    cardProcessingCostBRL?: number;
    maxAntecipationCostBRL?: number;
    pdfUrl?: string;
}

