export * from './cardIssuerFees.service';
import { CardIssuerFeesService } from './cardIssuerFees.service';
export * from './countries.service';
import { CountriesService } from './countries.service';
export * from './cpf.service';
import { CpfService } from './cpf.service';
export * from './documents.service';
import { DocumentsService } from './documents.service';
export * from './emails.service';
import { EmailsService } from './emails.service';
export * from './establishments.service';
import { EstablishmentsService } from './establishments.service';
export * from './establishmentsAddresses.service';
import { EstablishmentsAddressesService } from './establishmentsAddresses.service';
export * from './establishmentsComplianceForm.service';
import { EstablishmentsComplianceFormService } from './establishmentsComplianceForm.service';
export * from './establishmentsSignatories.service';
import { EstablishmentsSignatoriesService } from './establishmentsSignatories.service';
export * from './files.service';
import { FilesService } from './files.service';
export * from './identityToolkit.service';
import { IdentityToolkitService } from './identityToolkit.service';
export * from './logs.service';
import { LogsService } from './logs.service';
export * from './notifications.service';
import { NotificationsService } from './notifications.service';
export * from './paymentLinks.service';
import { PaymentLinksService } from './paymentLinks.service';
export * from './paymentMethods.service';
import { PaymentMethodsService } from './paymentMethods.service';
export * from './payments.service';
import { PaymentsService } from './payments.service';
export * from './pos.service';
import { PosService } from './pos.service';
export * from './readMethods.service';
import { ReadMethodsService } from './readMethods.service';
export * from './returneds.service';
import { ReturnedsService } from './returneds.service';
export * from './settings.service';
import { SettingsService } from './settings.service';
export * from './settingsUSD.service';
import { SettingsUSDService } from './settingsUSD.service';
export * from './transactions.service';
import { TransactionsService } from './transactions.service';
export * from './upload.service';
import { UploadService } from './upload.service';
export const APIS = [CardIssuerFeesService, CountriesService, CpfService, DocumentsService, EmailsService, EstablishmentsService, EstablishmentsAddressesService, EstablishmentsComplianceFormService, EstablishmentsSignatoriesService, FilesService, IdentityToolkitService, LogsService, NotificationsService, PaymentLinksService, PaymentMethodsService, PaymentsService, PosService, ReadMethodsService, ReturnedsService, SettingsService, SettingsUSDService, TransactionsService, UploadService];
