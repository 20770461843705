import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CountriesService, EstablishmentsService } from 'api-client';
import { PrimeNGConfig } from 'primeng/api';
import { take } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private primengConfig: PrimeNGConfig,
    private _translateService: TranslateService,
    private _establishmentsService: EstablishmentsService,
    private _countriesService: CountriesService
  ) {
    // Add languages
    this._translateService.addLangs(['pt', 'en']);

    // Set the default language
    this._translateService.setDefaultLang('pt');

    // Use a language
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this._establishmentsService
        .apiEstablishmentsEmailPost(user.email)
        .pipe(take(1))
        .subscribe((result) => {
          this._countriesService
            .apiCountriesIdGet(result.countryID)
            .pipe(take(1))
            .subscribe((country) => {
              // Set the default language
              this._translateService.setDefaultLang(country.uiLanguage.trim());
              this._translateService.use(country.uiLanguage.trim());
            });
        });
    } else {
      this._translateService.use('pt');
    }
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }
}
