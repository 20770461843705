import { Injectable } from '@angular/core';
import { CountriesService, Establishments, NotificationsService, PaymentLinks } from 'api-client';
import { LogService } from './log.service';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedFunctionsService {
  constructor(
    private _logger: LogService,
    private _notificationsService: NotificationsService,
    private _countriesService: CountriesService
  ) {}

  convertBase64ToBlob(Base64Image: string): Blob {
    const parts = Base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = Uint8Array.from(decodedData, (x) => x.charCodeAt(0));
    return new Blob([uInt8Array], { type: imageType });
  }

  fixDateToUTC(date): Date {
    return this.subtractMinutes(new Date(date), new Date().getTimezoneOffset());
  }

  formatCPF(cpf) {
    cpf = cpf.replace(/[^\d]/g, '');
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  formatCurrency(value: number, symbol: string) {
    switch (symbol) {
      case 'BRL':
        symbol = 'R$';
        break;
      case 'USD':
        symbol = '$';
        break;
      case 'EUR':
        symbol = '€';
        break;
      default:
        break;
    }
    return symbol + ' ' + value.toFixed(2);
  }

  async getBase64ImageFromUrl(imageUrl: string): Promise<string | ArrayBuffer> {
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  getUiLanguageCode(countryId: number): string {
    this._countriesService.apiCountriesIdGet(countryId).subscribe(
      (result) => {
        return result.uiLanguage.trim().toLowerCase();
      },
      (error) => {
        this._logger.error(error);
        return 'pt';
      }
    );
    return 'pt';
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  }

  sendPaymentLinkNotificationToCustomer(
    paymentLink: PaymentLinks,
    establishment: Establishments,
    translationStrings: any
  ) {
    const uiLanguage = this.getUiLanguageCode(establishment?.countryID);
    const notification = {
      emailAddress: paymentLink?.customerEmail,
      subject: translationStrings?.emailSubject,
      body:
        translationStrings?.hello +
        ' <br/>' +
        translationStrings?.toCompletePurchase +
        ' ' +
        establishment?.establismentName +
        ' ' +
        translationStrings?.clickOn +
        ' <b>DreamLink</b> ' +
        translationStrings?.belowAndMakePayment +
        ' <br/><br/>' +
        "<a href='https://" +
        paymentLink?.link +
        "' target='_blank'>https://" +
        paymentLink?.link +
        '</a>' +
        "<br/><br/><img src='" +
        paymentLink?.qrCodeUrl +
        "' alt='qr-code' style='width: 250px; height:250px'>" +
        '<br/><br/>' +
        translationStrings?.explanation +
        '<br/><br/>' +
        translationStrings?.afterFinishPayment +
        ' ' +
        '<br/>' +
        translationStrings?.weHopeGoodExperience +
        ' ' +
        '<br/><br/>' +
        translationStrings?.greetingsFromTeam,
      emailTitle: translationStrings?.emailTitle,
      imageToUse: 'user-code.png',
      language: uiLanguage
    };

    this._logger.debug('notification', JSON.stringify(notification));

    this._notificationsService
      .apiNotificationsPost(notification)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this._logger.debug(result);
        },
        error: (error) => {
          this._logger.error(error);
        }
      });
  }

  sendPaymentLinkNotificationToAdmins(
    paymentLink: PaymentLinks,
    establishment: Establishments,
    translationStrings: any
  ) {
    const uiLanguage = this.getUiLanguageCode(establishment?.countryID);
    const notification = {
      emailAddress:
        'josematos@cloudworks.pt;leandronloyola@hotmail.com;waldyrvalente@dreampay.com.br' +
        (paymentLink?.currencyCode == 'USD' ? ';delmaviana@dreampay.com.br' : ''),
      subject: translationStrings?.emailSubject,
      body:
        'Parceiro: ' +
        establishment?.establismentName +
        '<br/>Cliente:' +
        paymentLink?.customerName +
        '<br/>Valor:' +
        paymentLink?.currencyCode +
        paymentLink?.valueEUR +
        "<br/>Link: <a href='https://" +
        paymentLink?.link +
        "' target='_blank'>https://" +
        paymentLink?.link +
        '</a>' +
        "<br/><img src='" +
        paymentLink?.qrCodeUrl +
        "' alt='qr-code' style='width: 250px; height:250px'>",
      emailTitle: translationStrings?.emailTitle,
      imageToUse: 'user-code.png',
      language: uiLanguage
    };

    this._logger.debug('notification', JSON.stringify(notification));

    this._notificationsService
      .apiNotificationsPost(notification)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this._logger.debug(result);
        },
        error: (error) => {
          this._logger.error(error);
        }
      });
  }

  sendPaymentLinkNotificationToEstablishment(
    paymentLink: PaymentLinks,
    establishment: Establishments,
    translationStrings: any
  ) {
    const uiLanguage = this.getUiLanguageCode(establishment?.countryID);
    const notification = {
      emailAddress: establishment?.emailAddress,
      subject: translationStrings?.emailSubject,
      body:
        'Parceiro: ' +
        establishment?.establismentName +
        '<br/>Cliente:' +
        paymentLink?.customerName +
        '<br/>Valor:' +
        paymentLink?.currencyCode +
        paymentLink?.valueEUR +
        "<br/>Link: <a href='https://" +
        paymentLink?.link +
        "' target='_blank'>https://" +
        paymentLink?.link +
        '</a>' +
        "<br/><img src='" +
        paymentLink?.qrCodeUrl +
        "' alt='qr-code' style='width: 250px; height:250px'>",
      emailTitle: translationStrings?.emailTitle,
      imageToUse: 'user-code.png',
      language: uiLanguage
    };

    this._logger.debug('notification', JSON.stringify(notification));

    this._notificationsService
      .apiNotificationsPost(notification)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this._logger.debug(result);
        },
        error: (error) => {
          this._logger.error(error);
        }
      });
  }

  subtractMinutes(date: Date, minutes: number): Date {
    return new Date(date.getTime() - minutes * 60000);
  }
}
