/**
 * DreamPay REST API
 * API to support DreamPay applications
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Countries { 
    id?: number;
    name: string;
    active?: boolean;
    currencyCode?: string;
    currencySymbol: string;
    currencySingle: string;
    currencyPlural: string;
    defaultComission?: number;
    contractLanguage: string;
    uiLanguage: string;
}

