/**
 * DreamPay REST API
 * API to support DreamPay applications
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CardIssuerFees { 
    id?: number;
    cardIssuer: string;
    allowDebit: boolean;
    debitFee?: number;
    oneMonthFee?: number;
    twoMonthsFee?: number;
    threeMonthsFee?: number;
    fourMonthsFee?: number;
    fiveMonthsFee?: number;
    sixMonthsFee?: number;
    sevenMonthsFee?: number;
    eightMonthsFee?: number;
    nineMonthsFee?: number;
    tenMonthsFee?: number;
    elevenMonthsFee?: number;
    twelveMonthsFee?: number;
}

