<div class="layout-topbar">
  <a class="layout-topbar-logo" routerLink="">
    <img
      src="assets/layout/images/{{
                layoutService.config.colorScheme === 'light'
                    ? 'dreampay-128x128'
                    : 'dreampay-128x128-dark-background'
            }}.png"
      alt="logo" />
    <span>DreamPay</span>
  </a>

  <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
    <i class="pi pi-bars"></i>
  </button>

  <button
    #topbarmenubutton
    class="p-link layout-topbar-menu-button layout-topbar-button"
    (click)="layoutService.showProfileSidebar()">
    <i class="pi pi-ellipsis-v"></i>
  </button>

  <div
    #topbarmenu
    class="layout-topbar-menu"
    [ngClass]="{
            'layout-topbar-menu-mobile-active':
                layoutService.state.profileSidebarVisible
        }">
    <button class="p-link layout-topbar-button" routerLink="/profile">
      <i class="pi pi-user"></i>
      <span>Profile</span>
    </button>

    <button class="p-link layout-topbar-button" (click)="handleSignOutClick()">
      <i class="pi pi-sign-out"></i>
      <span>Logout</span>
    </button>
  </div>
</div>
