import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AddApiKeyInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('api.dreampay.com.br/api') > 0) {
      request = request.clone({
        setHeaders: {
          ApiKey: '8edVQyVI35xjmKB4DVIhdiEAW0LTLRLY'
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
